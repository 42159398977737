import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'

export const WinesPageTemplate = () => {
  return (
    <section className="bg-dark pt-40 pb-8 md:pb-16">
      <div className="max-w-screen-xl mx-auto text-white text-center px-2 md:px-8">
        <h1 className="text-4xl mb-4">Em breve...</h1>
        <h2 className="mreaves text-lg mb-16">
          Aqui você encontrará os melhores vinhos.
        </h2>

        {/* <Link className="mreaves text-lg hover:text-primary" to="/cardapio">
          Enquanto isso você pode dar uma olhada em nosso Cardápio
        </Link> */}
      </div>
    </section>
  )
}

const WinesPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Carta de Vinhos</title>
      </Helmet>
      <WinesPageTemplate />
    </Layout>
  )
}

export default WinesPage

export const pageQuery = graphql`
  query WinesPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        template
        title
      }
    }
  }
`
